import React, { useState, useCallback } from 'react';
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';

import Layout from '../components/layout';
import SEO from '../components/seo'; 
import PageHeader from '../components/page-header';
import { nota2 } from '../components/helpers/photos';

import bg from '../images/notes/nota2d.jpeg';

const Nota2 = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <Layout lightheader>
      <SEO title="Sanitización y Nebulización en Frío C100 Nuestras Instalaciones" />
      <div className="page about-page">
        <PageHeader bg={bg} />
        <div className="wrapper content">
          <h1>Sanitización y Nebulización en Frío C100 Nuestras Instalaciones</h1>
          <div className="row">
            <div className="col">
              <p>
                Informamos a toda la comunidad escolar que el pasado 13 de marzo se sanitizaron y vaporizaron las instalaciones del colegio como medida preventiva en beneficio de todos nuestros alumnos y todo el personal docente, de apoyo y directivo, para eliminar cualquier virus en nuestras instalaciones.
              </p>
              <p>
                La Organización Mundial de la Salud (OMS) recomienda el uso de equipo ULV tecnología de nebulización en interiores, el cual rápidamente puede cubrir un área grande y desinfectar a profundidad cualquier área en donde se aplique.
              </p>
              <p>
                El servicio de sanitizado de áreas y superficies se realiza en dos pasos utilizando equipo que produce vapor seco saturado a una temperatura superior a los 150°C con el que se desinfectan las superficies y posteriormente con el equipo de aspersión por nebulización ULV se aplica desinfectante 100% orgánico, altamente concentrado con rapidez de acción en menos de 60 segundos, es decir, es de amplio espectro.
              </p>
              <p>
                A este procedimiento se invitaron a Madres de Familia quienes participaron activamente en el proceso.
              </p>
            </div>
          </div>
          <hr/>
          <Gallery photos={nota2} onClick={openLightbox} />
          <ModalGateway>
            {viewerIsOpen ? (
              <Modal onClose={closeLightbox}>
                <Carousel
                  currentIndex={currentImage}
                  views={nota2.map(x => ({
                    ...x,
                    srcset: x.srcSet,
                    caption: x.title
                  }))}
                />
              </Modal>
            ) : null}
          </ModalGateway>
        </div>
      </div>
    </Layout>
  )
}

export default Nota2;